import React, { useState, useEffect } from 'react';
import './App.css';
import { useApiUrl } from './ApiContext';

const logo = `${process.env.PUBLIC_URL}/prompteo-logo.png`;

const App: React.FC = () => {
  const [message, setMessage] = useState<string>('');
  const apiUrl = useApiUrl();

  useEffect(() => {
    fetch(`${apiUrl}/api/hello`, {
      headers: {
        'X-API-CLIENT': 'prompteo-frontend'
      }
    })
      .then(response => response.json())
      .then(data => setMessage(data.message))
      .catch(error => console.error('Error fetching data:', error));
  }, [apiUrl]);

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 to-gray-800 flex flex-col justify-center items-center p-4">
      <div className="animate-bounce mb-8">
        <img 
          src={logo} 
          alt="Prompteo Logo" 
          className="w-24 h-24 md:w-32 md:h-32 object-contain filter drop-shadow-lg hover:scale-110 transition-transform duration-300"
        />
      </div>
      
      <h1 className="text-4xl md:text-6xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-red-400 to-purple-600 mb-6 text-center">
        Welcome to Prompteo.AI
      </h1>
      
      <p className="text-lg md:text-xl text-gray-300 font-light max-w-md text-center animate-fade-in">
        {message || 'Loading...'}
      </p>
      
      <div className="mt-12 space-x-4">
        <a href="https://prompteo.ai/" className="px-6 py-3 bg-purple-600 hover:bg-red-700 text-white rounded-lg transition-colors duration-300 shadow-lg hover:shadow-xl">
            Sign up
        </a>
      </div>
    </div>
  );
};

export default App;