import React, { createContext, useContext, ReactNode } from 'react';

const apiUrl = process.env.REACT_APP_API_URL;
console.log('API URL FROM CONTEXT:', apiUrl);

const ApiContext = createContext<string | undefined>(undefined);

export const useApiUrl = (): string => {
  const context = useContext(ApiContext);
  if (!context) {
    throw new Error('useApiUrl must be used within an ApiProvider');
  }
  return context;
};

interface ApiProviderProps {
  children: ReactNode;
}

export const ApiProvider: React.FC<ApiProviderProps> = ({ children }) => (
  <ApiContext.Provider value={apiUrl}>
    {children}
  </ApiContext.Provider>
);